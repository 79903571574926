let features = [
    {
        icon:  require('@/assets/img/icons/quotation.svg'),
        title: 'Quotation',
        onlyOn: ['home'],
        target: ['bo', 'accountant'],
        description: 'Create unlimited quotations with multiple templates, send to your customers and convert to invoice once done deal.'
    },
    {
        icon: require('@/assets/img/icons/invoice.svg'),
        title: 'Invoicing',
        onlyOn: ['home'],
        target: ['bo', 'accountant'],
        description: 'Create unlimited invoices with multiple templates, send to your customers, track your sales.'
    },
    {
        icon:  require('@/assets/img/icons/payments.svg'),
        title: 'Payments',
        onlyOn: ['home'],
        target: ['bo', 'accountant'],
        description: 'Collect payments, store payment info and send receipts. Customer can scan QR on invoice to pay as well.'
    },
    {
        icon:  require('@/assets/img/icons/customer.svg'),
        title: 'Customers',
        onlyOn: ['home'],
        target: ['bo', 'accountant'],
        description: 'A mini CRM, store all your customers information, address, contacts, and view their history with your business.'
    },
    {
        icon:  require('@/assets/img/icons/accounting.svg'),
        title: 'Accounting',
        onlyOn: ['home'],
        target: ['bo', 'accountant'],
        description: 'Complies with accounting standards, with debtor, creditor codes, account payables & receivables and export balance sheets. '
    },
    {
        icon:  require('@/assets/img/icons/reporting.svg'),
        title: 'Reporting',
        onlyOn: ['home'],
        target: ['bo', 'accountant'],
        description: 'Know your business health with multiple reports: Sales report, Statement of Account, Balance sheet and more.'
    },

]

export default features