<template>
  <div class="home">
    <Nav></Nav>
    <Header></Header>
    <FeatureSection></FeatureSection>
    <ProcessSection></ProcessSection>

    <section id="sign_up" class="py-16">
      <SignUpBox></SignUpBox>
    </section>
    <section id="partners" class="py-16">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-800 sm:text-4xl md:mx-auto">
          Our Partners<span class="text-green-500">.</span>
        </h2>
      </div>
      <div class="max-w-xl md:mx-auto sm:text-center">
        <div class="flex flex-col sm:flex-row">
          <a href="https://www.payex.io/" target="_blank" class="flex flex-col mx-5">
            <img :src="require('@/assets/img/partners/payex-logo.png')" width="200" style="height: 50px;object-fit: contain" alt="Payex"/>
            <p class="text-gray-800 font-semibold mt-2">Payment Gateway</p>
          </a>
          <a href="https://capsphere.com.my" target="_blank" class="flex flex-col mx-5">
            <img :src="require('@/assets/img/partners/capsphere.png')" width="250" alt="Capsphere"/>
            <p class="text-gray-800 font-semibold mt-2">Asset-based P2P Financing</p>
          </a>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/Header'
import Nav from './components/Nav'
import FeatureSection from './components/FeatureSection'
import ProcessSection from './components/ProcessSection'
import Footer from './components/Footer'
import SignUpBox from './components/SignUpBox'
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Header,
    Nav,
    FeatureSection,
    ProcessSection,
    SignUpBox,
    Footer
  }
}
</script>

<style lang="scss">
#sign_up {
  box-shadow: -10px 0px 10px rgba(0,0,0,0.1);
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%234caf50' fill-opacity='0.07' fill-rule='evenodd'/%3E%3C/svg%3E");
}
</style>
