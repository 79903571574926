<template>
  <section id="header" class="relative px-4 pt-16 mx-auto lg:py-32 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full">
    <div class="max-w-xl mx-auto lg:max-w-screen-xl">
      <div class="mb-16 lg:max-w-lg lg:mb-0">
        <div class="max-w-xl mb-6">
          <div>
            <p class="animate__animated animate__delay-1s animate__fadeIn inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Free to use
            </p>
          </div>
          <h2 class="animate__animated animate__slideInLeft max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Quotation, Invoicing, Payments<br class="hidden md:block" />
            should never
            <span class="inline-block text-green-500">be a hassle.</span>
          </h2>
          <p class="animate__animated animate__slideInLeft text-base text-gray-700 md:text-lg">
            DuitCount is a Cloud Invoicing, Accounting platform <br/> made for business owners, accountants of all sizes.<br/>
          </p>
        </div>
        <div class="flex items-center animate__animated animate__slideInLeft">
          <a
              href="#sign_up"
              class="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none"
          >
            Get started
          </a>
          <a href="#features" aria-label="" class="inline-flex items-center font-semibold transition-colors duration-200 text-green-500 hover:text-deep-800">Learn more</a>
        </div>
      </div>
    </div>
    <div style="z-index: -1" class="relative animate__animated animate__slideInUp flex justify-center h-full lg:w-2/3 xl:w-1/2 lg:absolute lg:justify-start lg:bottom-0 lg:right-0 lg:items-end">
      <div style="width: 50px; height: 50px;top: 20px; left:50px;" class="bubble animate__animated animate__delay-1s animate__fadeInBottomRight hidden md:block absolute bg-green-500">
        <div style="width: 80%; height: 80%; margin-top: 10%" class="bubble bg-white mx-auto my-auto text-center"></div>
      </div>
      <div style="width: 100px; height: 100px;top: 55px; left:-20px;" class="bubble animate__animated animate__delay-1s animate__fadeInBottomRight hidden md:block absolute bg-green-500">
        <div style="width: 80%; height: 80%; margin-top: 10%" class="bubble bg-white mx-auto my-auto text-center"></div>
      </div>
      <div style="width: 250px; height: 250px;bottom: -120px; right:50px;" class="bubble animate__animated animate__delay-1s animate__fadeInBottomLeft hidden md:block absolute bg-green-500">
        <div style="width: 80%; height: 80%; margin-top: 10%" class="bubble bg-white mx-auto my-auto text-center"></div>
      </div>
      <img style="z-index: 0; box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.6);"
           :src="require('@/assets/img/frame_safari_dark.png')"
           class="overflow-x-hidden object-cover object-top w-full h-64 max-w-xl -mb-16 rounded lg:ml-64 xl:ml-8 lg:-mb-10 xl:-mb-10 xxl:-mb-20 lg:h-auto lg:max-w-screen-md" alt="" />
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.bubble {
  clip-path: circle(50% at 50% 50%);
}
</style>