<template>
  <!-- card container -->
  <div class="max-w-6xl mx-auto px-4 md:px-0">
    <!-- card wrapper -->
    <div class="flex flex-row wrapper rounded bg-white rounded-sm shadow-lg border-2 border-green-600">
      <div class="hidden md:w-1/2 md:block bg-cover" :style="'background-image: url(' + require('@/assets/img/register.jpg') +')'"></div>
      <div class="w-full md:w-1/2 card px-8 py-4">

        <div class="card-text mt-16">
          <h1 class="text-xl md:text-2xl font-bold leading-tight text-gray-900">{{ title }}</h1>
          <p class="text-base md:text-lg text-gray-700 mt-3" v-html="description"></p>
        </div>

        <div class="card-mail flex items-center my-10">
          <form action="#" @submit.prevent="register" class="w-full flex flex-row">
            <input type="email"
                   required
                   v-model="email"
                   class="w-3/4 focus:outline-none border-l border-t border-b border-gray-400 rounded-l-md text-base md:text-lg px-3 py-2 focus:border-green-500"
                   placeholder="Enter Your Email">
            <button type="submit"
                    class="w-1/4 focus:outline-none bg-green-500 hover:bg-green-400 hover:border-green-600 text-white font-bold capitalize px-3 py-2 text-base md:text-lg rounded-r-md border-t border-r border-b border-green-500">
              {{ button }}
            </button>
          </form>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpBox',
  props: [
      'marketingButton',
      'marketingTitle',
      'marketingDescription'
  ],
  data () {
    return {
      email: null,
      title: 'Start Using Now',
      description: 'It only takes less than 5 minutes to setup an account, <br/> Its Free, no card required.</p>',
      button: 'Register'
    }
  },
  mounted() {
    if (this.marketingButton)
    {
      this.button = this.marketingButton;
    }
    if (this.marketingTitle)
    {
      this.title = this.marketingTitle;
    }

    if (this.marketingDescription)
    {
      this.description = this.marketingDescription;
    }
  },
  methods: {
    register () {
      if (!this.email) {
        return false
      }
      // redirect to registration with email
      window.location.href = `https://app.duitcount.my/auth/register?email=${this.email}`
    }
  }
}
</script>
