<template>
  <div class="relative w-max-7xl mx-auto px-4 md:px-8 xl:px-20 lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none">
    <nav class="w-full">
      <div class="md:flex items-center justify-between py-2">
        <div class="flex justify-between items-center">
          <div class="text-2xl font-bold text-gray-800 md:text-3xl xl:pl-8">
            <router-link :to="{name: 'Home'}">
              <img :src="require('@/assets/img/small-logo-duitcount.png')" width="120px"/>
            </router-link>
          </div>
          <div class="md:hidden">
            <button type="button" v-on:click.prevent="toggleMobileMenu"
                    class="block text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none">
              <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path class="hidden" d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"/>
                <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="flex flex-col md:flex-row md:block -mx-2" :class="{'hidden': !mobileMenuVisible, 'shadow-lg transition-all duration-200 p-5': mobileMenuVisible}">
          <router-link :to="{name: 'Home'}" class="text-gray-800 hover:text-underline hover:text-green-500 active:text-green-500 y-2 px-2 md:mx-2">
            Home
          </router-link>
          <router-link :to="{name: 'Features'}" class="text-gray-800 hover:text-underline hover:text-green-500 active:text-green-500 py-2 px-2 md:mx-2">
            Features
          </router-link>
          <router-link :to="{name: 'Pricing'}" class="text-gray-800 hover:text-underline hover:text-green-500 active:text-green-500 py-2 px-2 md:mx-2">
            Pricing
          </router-link>
          <a href="https://app.duitcount.my/docs" class="text-gray-800 hover:text-underline hover:text-green-500 active:text-green-500 py-2 px-2 md:mx-2">
            Documentation
          </a>
          <a href="https://app.duitcount.my" class="mt-5 md:ml-3 inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none">
            Log In / Register
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data () {
    return {
      mobileMenuVisible: false,
    }
  },
  methods: {
    toggleMobileMenu () {
      this.mobileMenuVisible = !this.mobileMenuVisible
    }
  }
}
</script>
