<template>
  <section id="features" class="px-4 bg-green-500 w-full py-16 mx-auto lg:py-20" style="z-index: 1;box-shadow: -10px 0px 20px -6px rgba(0,0,0,0.6);">
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
        Features that grow with you<span class="text-green-500">.</span>
      </h2>
      <p class="text-base text-white md:text-lg">
        DuitCount offers all accounting features, from quotation, to payment collection.<br/>
        Scales with your business, yet friendly to beginners.
      </p>
    </div>
    <div class="grid max-w-screen-lg mx-auto space-y-6 lg:grid-cols-2 lg:space-y-0 lg:divide-x">
      <div class="space-y-6 sm:px-16" v-bind:key="i" v-for="i in Math.ceil(features.length / 3)">
        <div class="flex flex-col max-w-md sm:flex-row"
             v-bind:key="feature.title"
             v-for="feature in featureList.slice((i - 1) * 3, i * 3)">
          <div class="mb-4 mr-4">
            <div v-if="feature.icon"
                 class="flex items-center justify-center w-16 h-16 bg-transparent">
              <img :src="feature.icon" class="text-white p-2"/>
            </div>
          </div>
          <div>
            <h6 class="mb-3 text-white text-xl font-bold leading-5">{{ feature.title }}</h6>
            <p class="text-sm text-white">
              {{ feature.description }}
            </p>
          </div>
        </div><!--/feature-->
      </div><!--/feature column-->
    </div>
    <div class="pt-10 w-full text-center justify-center">
      <router-link :to="{name: 'Features'}" class="rounded shadow-lg hover:shadow-xl bg-green-600 hover:bg-green-700 text-white py-4 px-6 font-semibold items-center focus:shadow-outline focus:outline-none">
        See More Features
      </router-link>
    </div>
  </section>
</template>

<script>
import features from "@/constants/FeatureList";
export default {
  name: 'FeatureSection',
  data () {
    return {
      features: null
    }
  },
  created () {
    this.features = features
  },
  computed : {
    featureList () {
      return this.features.filter((feature) => {
        return (feature.onlyOn.indexOf('home') > -1)
      })
    }
  }
}
</script>